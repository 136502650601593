

export const QuestionData = [
  {
    id: 0,
    title: "그럼 바로 시작해 볼까요? :)",
  },
  {
    id: 1,
    title: "초등학생 때 꿈꾼 직업은?"
  },
  {
    id: 2,
    title: "친구들 사이에서 내 별명은?"
  },
  {
    id: 3,
    title: "가장 여행하고 싶은 나라는?"
  },
  {
    id: 4,
    title: "가장 좋아하는 계절은?"
  },
  {
    id: 5,
    title: "가장 좋아하는 색깔은?"
  },
  {
    id: 6,
    title: "답변을 기다리는중입니다."
  }
];