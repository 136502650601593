import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import axios from 'axios';
import Swal from "sweetalert2";
import user_img from '../assets/person-circle-outline.svg';
import profile_img from '../assets/main_image.png';
import loading_img from '../assets/loading.gif';



const Chat = () => {
  // 사용자 단발성 답변 저장
  const [userAnswerInput, setuserAnswerInput] = useState("");
  const [userAnswer, setuserAnswer] = useState("");
  const [userPreviousText, setUserPreviousText] = useState([]);
  const questionNum = QuestionData.length - 1;
  // 사용자 모든 답변 저장
  const [userResponse, setUserResponse] = useState([]);
  // 사용자 답변 개수 트래킹용 
  const [questionId, setQuestionId] = useState(0);
  const isChtaGPTrequest = useRef(false);
  const API_URL = 'https://zb8q0n0ei1.execute-api.ap-northeast-2.amazonaws.com/prod/cpl-dev' 

  // 사용자 답변 저장
  const handleChange = ({ target: { value } }) => {
    setuserAnswerInput(value);
  };
  // 엔터 버튼 클릭 && 내용 포함 시만 함수 동작
  const handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      const inputValue = event.target.value;
      const trimmedInputValue = inputValue.trim();
      if (trimmedInputValue.length !==0) {
        handleSubmit();
      } else {
        Swal.fire({
          icon: 'warning',
          title: '내용을 입력하세요!',
          confirmButtonText: '알겠습니다',
          allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
          allowEscapeKey: false,
        });
        // 텍스트 입력창 초기화
        setuserAnswerInput("");
      }

    }
  }

  // 사용자 답변 전송
  const handleSubmit = () => {
    setuserAnswer(userAnswerInput);

    // 배열 추가 원소
    const newAnswer = [{
      id: questionId,
      text: userAnswerInput,
    }]
    // 텍스트 입력창 초기화
    setuserAnswerInput("");

    // 맨 처음 가이드라인성 질문은 배열 추가 안 함
    if (questionId > 0) {
      setUserPreviousText(userPreviousText.concat(newAnswer));
      setUserResponse(userResponse.concat(newAnswer));
    };

    // 쿠팡 질문까지만 받기
    if (questionId <= questionNum) {
      setQuestionId(questionId + 1);
    }

    // [임시] 화면에 사용자 답변 출력/콘솔
    userResponse.map((res) => {
      console.log(`res.id: ${res.id}, res.text: ${res.text}`)
    })
  };

  // 에이전트 현재 질문
  const showQuestion = (id) => {
    // 첫 질문
    if (id == 0) {
      return (
        <div>
          <div class="chat ch1">
          <img class='icon' src={profile_img} alt="profile_img"/>
            <div class="textbox">
              <QuestionText>안녕하세요, 저는 당신의 전생을 확인시켜 드릴 전생 마스터입니다.</QuestionText>
            </div>
          </div>
          <div class="chat ch1">
          <img class='icon' src={profile_img} alt="profile_img"/>
            <div class="textbox">
              <QuestionText> 몇 가지 질문사항에 <Questionpoint>한 단어</Questionpoint>로 답해 주세요.
                </QuestionText>
            </div>
          </div>
          <div class="chat ch1">
          <img class='icon' src={profile_img} alt="profile_img"/>
            <div class="textbox">
              <QuestionText> 그럼 바로 시작해 볼까요? :)</QuestionText>
            </div>
          </div>
        </div>
      )
    }
    // 후속 질문
    else {
      if (id <= questionNum) {
        return (
          <div>
            <div class="chat ch1">
            <img class='icon' src={profile_img} alt="profile_img"/>
              <div class="textbox">
                <QuestionText>{QuestionData[questionId - 1].title}</QuestionText>
              </div>
            </div>
            <div class="chat ch2">
            <img class='icon' src={user_img} alt="user_img"/>
              <div class="textbox">
                <QuestionText>{userAnswer}</QuestionText>
              </div>
            </div>
            <div class="chat ch1">
            <img class='icon' src={profile_img} alt="profile_img"/>
              <div class="textbox">
                <QuestionText>{QuestionData[questionId].title}</QuestionText>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const showTextInputBox = (id) => {
    if (id == questionNum && !isChtaGPTrequest.current) {
      isChtaGPTrequest.current = true
      try {

        //------------[팝업] ChatGPT 답변 대기 요청------------//
        Swal.fire({
          title: "🔎 전생 확인 중",
          html: "Chatgpt가 분석 중입니다🔥 <br><br>\
                최대 30초까지 소요될 수 있어요! <br>\
                잠시만 기다려주세요 :)",
          allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
          allowEscapeKey: false,
          imageUrl: loading_img,
          imageWidth: 100,
          imageHeight: 100,
          showConfirmButton: false,
        });
        //-----------------------------------------------//
        console.log(`Transfered Data from Client : ${userResponse}`);
        axios
          .post(API_URL, userResponse)
          .then((response) => {
            console.log(`Returned Data : ${response.data}`);
            console.log(`response.data.uuid : ${response.data.uuid}`);
            Swal.close();
            Swal.fire({
              title: '📌 공지',
              html : "안정적인 서버 운영비 조달을 위해<br> \
                      쿠팡 광고를 삽입했어요😅<br><br> \
                      쿠팡 광고 뜨면 놀라지 마시고<br> \
                      뒤로가기 누르시면 결과 확인하실 수 있어요🙏",
              icon: 'info',
              confirmButtonText: '쿠팡 광고보고 전생 확인하기',
              allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
              allowEscapeKey: false,
            }).then(() => {
              window.open('https://link.coupang.com/a/8S6Pb', '_blank');
              navigate(`/result/${response.data.uuid}`);
            });
          })
          // 에러 발생 시 에러 팝업 표시
          .catch((error) => {
            console.log(`error: ${error}`);
            // 대기 팝업 닫기
            Swal.close();
            Swal.fire({
              title: '오류 발생',
              text: `에러 메시지: ${error.message}`,
              icon: 'error',
              confirmButtonText: '다시 해보기',
              allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
              allowEscapeKey: false,
              
            }).then(() => {
              // 에러 발생 시 강제 새로고침 시키기
              window.location.reload();
            });
          });
        console.log(`AWS Lambda로 텍스트 전송!`);
      } catch (error) {
        console.log(`error: ${error}`);
      }
    }

    else if (id != questionNum) {
      return (
        <div class="wrap_input" style={{ textAlign: 'center' }}>
          <form id="send-container">
          <input
            type="text"
            id="message-input"
            class="form-control"
            maxlength="250"
            autocomplete="off"
            style={{height:"70px", width:"100%", margin: "0 auto"
          }}
            required
            value={userAnswerInput}
            onChange={handleChange}
            onKeyPress={handleKeyDown}
          />
          &nbsp;
          <button type="submit" id="send-button" class="btn btn-warning"
            style={{height:"70px", width:"100%", minWidth:"70px", maxWidth: "500px"}}
            onClick={handleSubmit}
            onKeyPress={handleKeyDown}
            disabled={!userAnswerInput}>
            전송
          </button>
        </form>
        </div>
      )
    }

  }

  // 이전 질문 돌아가기
  const moveback = () => {
    //--------가장 최근 삽입된 원소 정보 제거--------//
    const userResponsePoped = userResponse.pop()
    setuserAnswer(userResponsePoped.text);
    setQuestionId(questionId-1);
    setUserResponse(userResponse);
    
    //---------------------------------------//
  };
  

  const navigate = useNavigate();

  return (
    <div style={{}}>
      <Wrapper>
        <Title >Chat with GPT</Title>
        <button type="submit" id="send-button" class="btn btn-warning"
            style={{height: "40px", width:"100px",textAlign: "center",
            backgroundColor: "navy", border: "None", color: "white", marginRight: "12px"}}
            onClick={() => {navigate('/')}}
            >
            🏠 홈 이동
          </button>
          <button type="submit" id="send-button" class="btn btn-warning"
            style={{height:"40px", width:"100px",textAlign: "center",
            backgroundColor: "green", border: "None", color: "white"}}
            onClick={moveback}
            disabled={(questionId==0) ? true : false} // 웰컴메시지 일 때는 back 불가
            >
            이전 질문
          </button>
        <br></br>
        <br></br>
        <ProgressBar striped now={(questionId / QuestionData.length) * 100} />
        <div class="wrap">
          {showQuestion(QuestionData[questionId].id)}
        </div>
        {showTextInputBox(QuestionData[questionId].id)}
        
        <Adfit height={100} width={320} unit={"78CdruccVKYlqKy6"} scrFlag={true} position={"buttom"}></Adfit>
        <FooterWrapper>
      이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br/>이에 따른 일정액의 수수료를 제공받습니다.
      <br/><br />
      <iframe src="https://ads-partners.coupang.com/widgets.html?id=712312&template=carousel&trackingCode=AF6214453&subId=&width=230&height=60&tsource=" width="230" height="60" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
    </FooterWrapper>
        <Footer></Footer>
      </Wrapper>
      
    </div>
  );
}

export default Chat;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const QImage = styled.div`
  text-align: center;
`

const Wrapper = styled.div`
  height: 100vh;
  // height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center
  white-space: pre-line;
  `
const QuestionNo = styled.div`
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: white;
`
const Title = styled.div`
  padding: 1rem 1rem 1rem 1rem;
	font-size: 17px;
	text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

const ButtonGroup = styled.div`
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`

const QuestionText = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 15px;
  text-align: left;
  color: black;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

  const Questionpoint = styled.span`
  font-size: 15px;
  color: blue;
  font-family: "CookieRun_Regular";
  `
