import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import axios from 'axios';
import Swal from "sweetalert2";
import KakaoShareBtn from '../component/KakaoShareBtn';
import profile_img from '../assets/main_image.png';

//--------------------다른 테스트 썸네일--------------------//
import TestMZOimg from '../assets/mz-title.PNG';
import TestRRSimg from '../assets/rrs-title2.png';
import TestNYRimg from '../assets/nyr-title.png';
import TestKTAimg from '../assets/kta-title.png';
//-----------------------------------------------------//

import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Result = () => {

  //---------------------URL 기반 UUID 추출----------------------//
  const location = useLocation();
  const pathname = location.pathname;
  const UUID = pathname.substring(pathname.indexOf("/result/") + "/result/".length);
  const shareURL = `https://previouslife.click/result/${UUID}`;
  const API_URL = 'https://zb8q0n0ei1.execute-api.ap-northeast-2.amazonaws.com/prod/cpl-dev'
  const TR_API_URL = 'https://zb8q0n0ei1.execute-api.ap-northeast-2.amazonaws.com/prod_translate_cors/translate'

  //-----------------------------------------------------------//

  const [chatGPTresponse, setChatGPTresponse] = useState(null);
  const isChtaGPTresponse = useRef(false);
  const isTranslatedResponse = useRef(false);
  const [translateoutput, setTranslateoutput] = useState(null);

  useEffect(() => {
    if (isChtaGPTresponse.current) {
        translateko(chatGPTresponse)
            .then((data) => {
                console.log(`translateR: ${data.translateResponse}`);;
                setTranslateoutput(data.translateResponse);
                isTranslatedResponse.current=true;
            });
    }
    }, [chatGPTresponse]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await axios.get(API_URL, {
        params: {
          uuid: UUID,
        }
      })
        .then(response => {
          if (isMounted) {
            console.log(`response: ${response.data.chatGPTresponse}`);
            setChatGPTresponse(response.data.chatGPTresponse);
            isChtaGPTresponse.current = true;
          }
        })
        .catch(error => {
          console.error(error);
        })
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, []);

    const parsing_response = (str) => {
        console.log(`beforeparse: ${str}`);
        // str = str.replace(/"/g, '');  // 쌍따옴표 제거
        // console.log(`gtag: ${str}`);

        // const keyValuePairs = str.slice(1, -1).match(/'(.*?)':'(.*?)'/g);
        // if (!keyValuePairs) return {};
        // const parsedObj = {};
        // keyValuePairs.forEach(pair => {
        // const [key, value] = pair.slice(1, -1).split("':'");
        // parsedObj[key] = value;
        // });

        const parsedObj = JSON.parse(str);

        console.log(`afterparse: ${parsedObj.직업}`);
        console.log(`afterparse: ${parsedObj.시대}`);
        console.log(`afterparse: ${parsedObj.상세한이유}`);

        return parsedObj;
    };

    const translateko = (str) => {
        if (isChtaGPTresponse.current) {
            console.log(`${chatGPTresponse}`);
            // console.log(`type: ${typeof chatGPTresponse}`);
            const parsedResponse = parsing_response(chatGPTresponse);
            // const parsedResponse = parsing_response("{\"시대\":\"Stone Age\",\"직업\":\"Healer\",\"상세한이유\":\"In your past life, you were known as the healer of your tribe. Your interest in medicine started at a young age, and you were always fascinated by the healing powers of plants and herbs. Your nickname, 개미, was given to you because of your tireless work ethic and your ability to carry heavy loads. Your dream of traveling to Italy may have been inspired by your fascination with ancient Roman medicine. Your favorite season, winter, may have been the time when you gathered and prepared medicinal plants for the upcoming year. And your favorite color, blue, may have been the color of the rare and valuable \"h\"ealing stones you collected and used in your practice.\"}");
            console.log(`reason2: ${parsedResponse.직업}`);
            console.log(`reason3: ${parsedResponse.시대}`);
            console.log(`reason4: ${parsedResponse.상세한이유}`);

            // const eng = `You were ${parsedResponse.직업} in the ${parsedResponse.시대}. ${parsedResponse.상세한이유} `;
            const eng = `${parsedResponse.상세한이유} `;

            // const params = {'en_data' : eng}
            console.log(`eng: ${eng}`);
            return axios
                .post(TR_API_URL, eng)
                .then((response) => {
                    console.log(`Returned Data : ${response.data.translateResponse}`);
                    return response.data;
                })
                .catch((error) => {
                    console.log(`error: ${error}`);
                });
        }
            // try {
            //     //post, response
            //     axios
            //     .post(TR_API_URL, eng)
            //     .then((response) => {
            //       console.log(`Returned Data : ${response.data}`);
            //     })
            //     .catch((error) => {
            //       console.log(`error: ${error}`);
            //     });
            // }catch (error) {
            //     console.log(`error: ${error}`);
            // }
        // }
        // return response.data;
    }

    const showResponse = () => {
    if (isChtaGPTresponse.current) {
        // const translateoutput = translateko(chatGPTresponse);
        //// const parsedResponse = parsing_response(chatGPTresponse);
        //// console.log(`response: ${JSON.sxotringify(parsedResponse)}`);
        return (
        <div>
            <div class="chat ch1">
              <img class='icon' src={profile_img} alt="profile_img" />
              <div class="textbox">
                <QuestionText>당신의 전생 스토리를 알려드릴게요!</QuestionText>
              </div>
            </div>
            <div class="chat ch1">
              <img class='icon' src={profile_img} alt="profile_img" />
              <div class="textbox">
                  <QuestionText>
                      {translateoutput}
                  </QuestionText>
              </div>
            </div>
            <div class="chat ch1">
              <img class='icon' src={profile_img} alt="profile_img" />
              <div class="textbox">
                  <QuestionText>
                      친구들한테도 ChatGPT 전생스토리를 공유해 보세요! 😊
                  </QuestionText>
              </div>
            </div>
        </div>
        );
    }
    }

  const showNotice = () => {
    Swal.fire({
      icon: 'info',
      title: 'ChatGPT 답변 관련 Notice',
      html : 'ChatGPT는 인터넷 데이터를 검색 및 가공하므로<br>개발자 의도와 상관 없이 폭력/선정적 내용이 포함될 수 있습니다.<br><br>\
              전생 스토리는 ChatGPT라는 인공지능이 창의적으로<br>생성한 데이터로 사실과 다를 수 있음을 알려드립니다.',
      type: 'info',
      confirmButtonText: '네, 이해했습니다',
      allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
      allowEscapeKey: false,

    });
  }

  const showNoticeCopyURL = () => {
    Swal.fire({
      icon: 'success',
      title: 'URL 복사완료!',
      type: 'info',
      confirmButtonText: 'OK',
    }); 
  }

  const showShareButton = () =>{
    if (isTranslatedResponse.current){
      return (
        <div>
          <GridContainer>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={shareURL}>
              <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
            </FacebookMessengerShareButton>
            {/* <TwitterShareButton url={currentUrl}>
                   <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
                 </TwitterShareButton> */}
            <CopyToClipboard text={shareURL}>
              <URLShareButton onClick={()=>{showNoticeCopyURL()}}>URL</URLShareButton>
            </CopyToClipboard>
            {/* 수정필요 : responseData 그대로 들어가면 kakao share button 동작안할거 */}
            <KakaoShareBtn data={{ data: translateoutput, UUID : UUID }} />
            {/* <KakaoShareBtn data={{ data: chatGPTresponse, pageID: 'resultPage' }} /> */}
          </GridContainer>
        </div>
      )
    }
  }

  const navigate = useNavigate();
  
  const showKatalkPlus = () => {
    const channelId = '_txjDHxj';
    window.open(`https://pf.kakao.com/${channelId}/chat`);
  };

  return (
    <div style={{}}>
      <Wrapper>
        <Title>ChatGPT 전생 분석결과</Title>
        <div class="wrap">
          {showResponse()}
        </div>
        <br/>
        <FlexContainer>
            <Button style={{ marginRight: '10px' }} className="btn btn-primary btn-lg"  onClick={() => { navigate('/'); }}>
                REPLAY </Button>
          <br/>
          <h5><font color="#FFFFFF">공유하기</font></h5>
          <br/>
          {showShareButton()}
        </FlexContainer>
        <h5 style={{ textAlign: "center" }} onClick={showNotice}><font color="grey" size="2">[ChatGPT 답변 관련 Notice]</font></h5>
        <Adfit height={100} width={320} unit={"78CdruccVKYlqKy6"} scrFlag={true} position={"buttom"}></Adfit>
        <TestContainer>
          <h4><font color="#FFFFFF">👇 다른 테스트하러 가기 👇</font></h4><br />
          <img src={TestMZOimg} width={230} height={60} onClick={() => { window.open('https://www.mzofficetest.click/'); }} /><br />
          <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br />
          <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://www.newyearrabbit.click/'); }} /><br />
          <img src={TestKTAimg} width={230} height={60} onClick={() => { window.open('https://www.advancer-katalk.click/'); }} /><br />
          <Button style={{color: 'white', backgroundColor : "rgba(246,194,68)", border: 'None'}} 
                  onClick={showKatalkPlus}>개발자에게 제안하기</Button>
        </TestContainer>
        
        <Footer></Footer>
      </Wrapper>
    </div>
  );
};

export default Result;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const QImage = styled.div`
  text-align: center;
`
const Wrapper = styled.div`
  //height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center
  white-space: pre-line;
  `
const Bar = styled.div`
  padding-top: 2rem;
`
const QuestionNo = styled.div`
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: white;
`
const Title = styled.div`
  padding: 1rem 1rem 1rem 1rem;
	font-size: 17px;
	text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

const ButtonGroup = styled.div`
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`

const QuestionText = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 15px;
  text-align: left;
  color: black;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `
// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;
const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const TestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`