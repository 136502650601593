import Home from './pages/Home';
import Chat from './pages/Chats';
import Result from './pages/Result';
// import Resultshare from './pages/Resultshare';
import { Routes, Route } from 'react-router-dom';
import Notfound from './pages/Notfound';
import './App.css';
import React from 'react';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/chat' element={<Chat />}></Route>
      <Route path="/result" element={<Result />}></Route>
      <Route path='/chat/*' element={<Chat />}></Route>
      <Route path='/result/:uuid' element={<Result />}></Route>
      <Route path="/*" element={<Notfound />} /> 
    </Routes>
  );
}

export default App;
