import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import mainimage from '../assets/main_image.png';
import mainimageGIF from '../assets/mainimage_gif.gif';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import KakaoShareBtn from '../component/KakaoShareBtn';
import ReactDOM from 'react-dom'
import Swal from "sweetalert2";
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
	FacebookShareButton,
	FacebookIcon,
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";



let accUserNum = 0;
const Home = () => {
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const handleClickButton = () => {
     navigate('/chat');
  }

  const showUserNum = () => {
    return '5만 명+';
  };

  const showNoticeCopyURL = () => {
    Swal.fire({
      icon: 'success',
      title: 'URL 복사완료!',
      type: 'info',
      confirmButtonText: 'OK',
    }); 
  };

  const showKatalkPlus = () => {
    const channelId = '_txjDHxj';
    window.open(`https://pf.kakao.com/${channelId}/chat`);
  };

  const resData = [
    {
      id: 1000,
      token: 'a42b7064df',
      name: '****',
      best: '200',
      desc: '****',
      image: mainimage,
      r_iamge: mainimage,
    }];

    return(
    <div style={{ }}>
    {/* <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} /> */}
    <Wrapper> 
      <Header>ChatGPT가 알려주는 전생 이야기</Header>
      <Contents>
      <Title>나는 전생에 어떤 사람?</Title>
        <br/>
        <LogoImage>
          <img alt='mainpage_img' src={mainimageGIF} width={300} height={300} className="rounded-circle" />
        </LogoImage>
                <br/>
                <br/>
        <Button style={{marginRight:'0px'}} class="btn btn-primary btn-lg" onClick={handleClickButton}>START</Button>
        <FlexContainer>
          <Desc>공유하기</Desc>
          <GridContainer>
            <FacebookShareButton url={currentUrl}>
              <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={currentUrl}>
              <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
            </FacebookMessengerShareButton>
            {/* <TwitterShareButton url={currentUrl}>
              <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
            </TwitterShareButton> */}
            <CopyToClipboard text={currentUrl}>
              <URLShareButton onClick={()=>{showNoticeCopyURL()}}>URL</URLShareButton>
            </CopyToClipboard>
            <KakaoShareBtn data={{data: resData, pageID: 'startPage'}} />
          </GridContainer>
        </FlexContainer>

        </Contents>
        <Adfit height={100} width={320} unit={"78CdruccVKYlqKy6"} scrFlag={true} position={"buttom"}></Adfit>
        {/* <Adfit height={50} width={320} unit={"8aakjosDTic30Zmc"} scrFlag={true} position={"middle"}></Adfit> */}
        <STitle>누적 테스트 수 : {showUserNum()}</STitle>
        <br></br>
        <Button style={{color: 'black', backgroundColor : "rgba(246,194,68)", border: 'None'}} 
                  onClick={showKatalkPlus}>개발자에게 제안하기</Button>
        <Footer> </Footer>
    </Wrapper>
    </div>
    );
}

export default Home;

const Wrapper = styled.div `
  // height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center;
  white-space: pre-line;
`
const Header = styled.div `
  padding-top: 2rem;
  font-size: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CookieRun_Regular";
  font-weight: 500;
  color: white;
  text-align: center
`
const Contents = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div `
  font-size: 25px;
  margin-top: 40px;
  color: white;
`

const STitle = styled.div `
  font-size: 17px;
  color: white;
`

const LogoImage = styled.div `
  margin-top: 20px;
`
const Desc = styled.div `
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;